export const SemCoreHome = require("./SemcoreHomeCropped.PNG")
export const SemCoreAnimation = require("./SemCoreAnimationCropped.gif")
export const GraphHome = require("./GraphAppHomePageCropped.PNG")
export const GraphAnimation = require("./GraphAnimationCropped.gif")
export const EcommerceHome = require("./EcommerceCropped.PNG")
export const ECommerceAnimation = require("./EcommerceAnimationCropped.gif")
export const NotesAppHome = require("./NotesAppHomePageCropped.PNG")
export const NotesAppMarkdown = require("./NotesAppMarkdownCropped.PNG")
export const PrettyMenuIMG = require("./PrettyMenuCropped.PNG")
export const PrettyMenuAnimation = require("./PrettyMenuAnimationCropped.gif")
export const QuizHomePage = require("./QuizHomePageCropped.PNG")
export const QuizResultsPage = require("./QuizResultsPageCropped.PNG")
export const StarbucksHome = require("./StarbucksSiteCropped.PNG")
export const StarbucksAnimation = require("./StarbucksSiteAnimationCropped.gif")
export const TwitterCloneHome = require("./TwitterCloneCropped.PNG")
export const TwitterCloneProfile = require("./TwitterCloneProfileCropped.PNG")
export const FrontEndDevStack = require("./FrontEndDevStack.PNG")
export const FrontEndDevStackAnimation = require("./FrontEndDevStackAnimation.gif")
export const ChatApp = require("./ChatApp.PNG")
export const ChatAppAnimation = require("./ChatApp.gif")
export const IpGeolocator = require("./IpGeolocator.PNG")
export const IpGeolocatorAnimation = require("./IpGeolocatorAnimation.gif")
export const NetflixClone = require("./NetflixClone.PNG")
export const NetflixCloneAnimation = require("./NetflixCloneAnimation.gif")
// export const SensoryConnect = require("./SensoryConnect.PNG")
// export const SensoryConnectAnimation = require("./SensoryConnectAnimation.gif")